function stripNonPhoneCharacter (input) {
  input = input.replace(/,|\s|(\(0\))|-|\./gi, '')
  input = input.replace(/\(|\)/gi, '')
  return input
}
/**
 * For local call, French add a leading 0 to the phone number which needs
 * to be removed when converted to internation phone number
 * @param {string} input A phone number to normalize
 */
export function normalizedFrenchPhoneFormat (input) {
  input = stripNonPhoneCharacter(input)
  if (input.startsWith('0')) {
    input = `${input.slice(1)}`
  }
  return input
}

/**
 * Remove all useless characters from a phone number and ensure
 * that it follows international format
 * @param {string} input A phone number to format
 */
export function phoneFormat (input) {
  input = stripNonPhoneCharacter(input)
  if (input.startsWith('00')) {
    input = `+${input.slice(2)}`
  }
  if (input.startsWith('0')) {
    input = `+33${input.slice(1)}`
  }
  if (input.startsWith('+') && input[3] === '0') {
    input = `${input.substr(0, 3)}${input.slice(4)}`
  }
  if (!input.startsWith('+')) {
    input = `+${input}`
  }
  input = input.substr(0, 12)
  return input
}
