<template>
  <VTextField
    ref="$input"
    v-model="number"
    placeholder="Téléphone"
    type="tel"
    autocomplete="none"
  />
</template>

<script setup lang="ts">
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import { parsePhoneNumber } from 'awesome-phonenumber'

interface Props {
  modelValue: number | string | null,
  errorMessages?: any[]
}

const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue', 'valid'])

const iti = ref()
const isValid = ref<null | boolean>(null)
const fullNumber = ref(null)
const countryData = ref<any>({
  iso2: 'fr',
  dialCode: '33'
})
const parsedNumber = ref()
const $input = ref()
const nationalNumber = computed(() => ((parsedNumber.value && parsedNumber.value.number && parsedNumber.value.number.national) || '').replace(/\s+/gmi, ''))
const number = computed({
  get () {
    return nationalNumber.value || props.modelValue
  },
  set (value) {
    _parsePhoneNumber(value)
    emit('update:modelValue', nationalNumber.value || value)
  }
})

watch(isValid, (isValid) => {
  if (isValid) {
    emit('valid', { isValid, countryData: countryData.value })
  }
})

watch(() => props.modelValue, () => {
  number.value = props.modelValue
})

onMounted(() => {
  try {
    const input = $input.value.$el.querySelector('input')
    iti.value = intlTelInput(input, {
      utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js',
      initialCountry: 'auto',
      geoIpLookup: (set: Function) => {
        fetch('https://ipapi.co/json')
          .then(res => res.json())
          .then(data => set(data.country_code))
          .catch(() => set('fr'))
      },
      separateDialCode: true,
      preferredCountries: ['fr', 'de', 'be', 'ch', 'es', 'it', 'uk', 'us']
    })

    input.addEventListener('countrychange', () => {
      isValid.value = parsedNumber.value && parsedNumber.value.valid && parsedNumber.value.typeIsMobile
      fullNumber.value = iti.value?.getNumber()
      countryData.value = iti.value?.getSelectedCountryData()
    })
  } catch (error) {
    console.log(error)
  }
})

function _parsePhoneNumber (value: string) {
  if (!value) {
    return
  }
  if (value.startsWith('+')) {
    const _parsedNumber = parsePhoneNumber(value || '')
    if (_parsedNumber.valid) {
      countryData.value = getCountryDataByCountryCode(_parsedNumber.countryCode)
      iti.value.setCountry(countryData.value.iso2.toUpperCase())
      parsedNumber.value = _parsedNumber
    }
  } else {
    parsedNumber.value = parsePhoneNumber(value || '', { regionCode: countryData.value.iso2.toUpperCase() })
  }
  isValid.value = parsedNumber.value && parsedNumber.value.valid && parsedNumber.value.typeIsMobile
  fullNumber.value = iti.value.getNumber()
}

function getCountries () {
  return window.intlTelInputGlobals.getCountryData()
}

function getCountryDataByCountryCode (countryCode: any) {
  return getCountries().find((countryData: any) => countryCode === +countryData.dialCode)
}
</script>

<style scoped>
:deep(.iti) {
  width: 100%;
}
</style>
