<template>
  <div>
    <VForm
      role="form"
    >
      <div
        v-show="state.generalError"
        class="alert alert-danger"
        v-html="state.generalError"
      />
      <p>
        Pour pouvoir continuer d'avancer ensemble, nous avons besoin de la confirmation de votre numéro de téléphone.<br><br>Un code de vérification vous a été adressé par SMS. Merci de saisir le code envoyé ci-dessous.
      </p>

      <VTextField
        v-model="state.code"
        label="Code de vérification"
        placeholder="Code de vérification"
        type="text"
      />
      <div
        v-show="state.codeError"
        class="alert alert-danger"
      >
        {{ state.codeError }}
      </div>
      <div class="text-center">
        <div
          class="btn-group"
          :class="{fullWidth:props.embedded}"
        >
          <HButton
            type="button"
            disabled-on-loading
            @click.prevent="props.embedded ? modifyNumber() : correct()"
          >
            Modifier le numéro
          </HButton>
          &nbsp;&nbsp;
          <HButton
            type="submit"
            disabled-on-loading
            @click.prevent="checkCode()"
          >
            Valider
          </HButton>
        </div>
      </div>
    </VForm>
  </div>
</template>

<script lang="ts" setup>
/**
 * A component to check a phone number by SMS or phone call. Relies on Foster.
 */
import { normalizedFrenchPhoneFormat } from '~/utils/phoneFormat'

interface Props {
  phone: string,
  countryCode: string
  contactId: number
  embedded: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['initialize:success', 'check:success', 'modifynumber', 'error'])
const { api } = useFeathers()

const state = reactive({
  sent: false,
  code: '',
  via: 'sms',
  codeError: '',
  generalError: '',
  iPhone: props.phone,
  iCountryCode: props.countryCode
})

onMounted(() => {
  // send code directly if form is embedded (not in funnel)
  nextTick(() => {
    if (props.embedded) {
      sendCode()
    }
  })
})

async function startPhoneVerification (contactId: number, phone: string, countryCode: string, via: string) {
  return await api.service('phone-verification').create({
    contactId,
    phone,
    countryCode,
    via
  })
}

async function checkPhoneVerification (contactId: number, phone: string, countryCode: string, code: string) {
  return await api.service('phone-verification').patch(
    contactId,
    {
      phone,
      countryCode,
      code
    }
  )
}

function sendCode () {
  state.iPhone = normalizedFrenchPhoneFormat(props.phone)
  state.iCountryCode = props.countryCode
  state.sent = true
  startPhoneVerification(props.contactId, state.iPhone, state.iCountryCode, state.via)
    .then((res) => {
      if (res.success) {
        emit('initialize:success')
      }
    })
    .catch((error) => {
      emit('error', error.data)
      state.generalError = 'Une erreur est survenue'
    })
}

function checkCode () {
  checkPhoneVerification(props.contactId, state.iPhone, state.iCountryCode, state.code).then(
    (res) => {
      if (res.success && res.status === 'approved') {
        emit('check:success')
      }
    },
    (error) => {
      console.log(error)
      emit('error', error.data)
      state.generalError = 'Une erreur est survenue'
    }
  )
}

function correct () {
  state.sent = false
  state.codeError = ''
  state.generalError = ''
  state.code = ''
}
function modifyNumber () {
  state.sent = false
  state.codeError = ''
  state.generalError = ''
  state.code = ''
  emit('modifynumber')
}

</script>

<style lang="sass" scoped>
.fullWidth
  width: 100%
  margin-bottom: 20px

</style>
